import React from 'react';
import PropTypes from 'prop-types';
import DefaultButton from './DefaultButton';
import Imgix, { Background } from 'react-imgix';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import Lazyload from 'react-lazyload';
import '../styles/components/PublicationItem.scss';

const PublicationItem = ({
  publication,
  has_section_image,
}) => (
  <div className="publication-container">
    {has_section_image && (
      <div className="publication-image-container">
        <Lazyload once height={219}>
          <Background
            className="lasyload blur-up img-background"
            imgixParams={{ h: 219 }}
            src={`${publication.metadata.publication_image && publication.metadata.publication_image.imgix_url ? publication.metadata.publication_image.imgix_url : 'https://imgix.cosmicjs.com/5c5eafe0-a135-11eb-a115-d559d5cc7c62-Publicationplaceholder.png'}?auto=format`}
          />
        </Lazyload>
      </div>
    )}
    <div className="publication-content-container">
      {publication.metadata.date_label && <p className="publication-date">{publication.metadata.date_label}</p>}
      <h3>{publication.metadata.publication_title}</h3>
      <div className="publication-description" dangerouslySetInnerHTML={{ __html: publication.metadata.publication_description}} />
      <DefaultButton title={publication.metadata.button_title || "LEARN MORE"} isLocal={publication.metadata.is_local} url={publication.metadata.link} extraClass="transparent" />
    </div>
  </div>
);

PublicationItem.propTypes = {
  has_section_image: PropTypes.bool,
  publication: PropTypes.object.isRequired,
};

PublicationItem.defaultProps = {
  has_section_image: false,
}

export default PublicationItem;