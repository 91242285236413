import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import '../styles/components/NextItem.scss';

const NextItem = ({
 nextPage
}) => (
  <Link
    to={nextPage.metadata.page_slug}
    className="next-item__container"
  >
    NEXT: {nextPage.title} {'>'}
  </Link>
);

NextItem.propTypes = {
  nextPage: PropTypes.object.isRequired,
};

export default NextItem;