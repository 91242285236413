import React from 'react';
import PropTypes from 'prop-types';
import Imgix from 'react-imgix';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/vinex.scss';
import NavigationItem from '../components/NavigationItem';
import Aim from '../components/Aim';
import BottomQuoteCTA from '../components/BottomQuoteCTA';
import useInlineParsingV2 from '../utils/inline-parsing.utils';
import TeamMember from '../components/TeamMember';
import AdvisoryBoard from '../components/AdvisoryBoard';
import Partner from '../components/Partner';
import PublicationItem from '../components/PublicationItem';
import NextItem from '../components/NextItem';

const VinexTemplate = ({ pageContext }) => {
  const { content, references, publications } = pageContext;
  const mountedContent = useInlineParsingV2(content);
  let mountedReferences = null;
  if (references !== null) {
    mountedReferences = useInlineParsingV2(references)
  }
  const { meta, page_contents, og_image, page_slug } = pageContext.metadata;
  console.log(page_contents);
  return (
    <Layout pathName={pageContext.pathname}>
      <SEO pathname={page_slug} canonical={page_slug} title={meta.meta_title} description={meta.meta_description} ogImage={og_image.imgix_url || null} />
      <div className={`vinex__top-container ${page_contents.metadata.top_background_image && page_contents.metadata.top_background_image.imgix_url ? 'has-bg_top' : ''}`}>
        <div className={`vinex__contents-container ${page_contents.metadata.top_background_image && page_contents.metadata.top_background_image.imgix_url ? 'has-bg' : ''}`}>
          {page_contents && page_contents.metadata && page_contents.metadata.next_page && page_contents.metadata.next_page.metadata && (
            <NextItem nextPage={page_contents.metadata.next_page} />
          )}
          <div className={`vinex__contents-inner-container`}>
            <div className="vinex__main-title" dangerouslySetInnerHTML={{ __html: page_contents.metadata.main_title}} />
            <div className="vinex__columned-content">
              {page_contents.metadata.column_1_content && (<div className="vinex__column-1" dangerouslySetInnerHTML={{ __html: page_contents.metadata.column_1_content }} />)}
              {page_contents.metadata.column_2_content && (<div className="vinex__column-2" dangerouslySetInnerHTML={{ __html: page_contents.metadata.column_2_content }} />)}
            </div>
          </div>
          {page_contents.metadata.top_background_image && page_contents.metadata.top_background_image.imgix_url && (<div className="vinex__top-image">
            <Imgix
              className="lazyload blur-up image"
              width={668}
              height={668}
              htmlAttributes={{
                alt: 'Rocket Health Science | Vinex',
                src: `${page_contents.metadata.top_background_image.imgix_url}?blur=500&px=4&auto=format`,
              }}
              src={`${page_contents.metadata.top_background_image.imgix_url}?auto=format`}
            />
          </div>)}
        </div>
      </div>
      {page_contents.metadata.text_and_cards && page_contents.metadata.text_and_cards.title && (
        <section className="vinex__text_and_cards_container">
          <h4>{page_contents.metadata.text_and_cards.title}</h4>
          <div className="vinex__body_paragraph" dangerouslySetInnerHTML={{ __html: page_contents.metadata.text_and_cards.paragraph_content }} />
          <div className="vinex__cards_container">
          {page_contents.metadata.text_and_cards.cards && page_contents.metadata.text_and_cards.cards.length > 0 && page_contents.metadata.text_and_cards.cards.map((card, index) => (
            <div className="vinex__card" key={`vinex_card-${index}`}>
              <div className="image">
                <Imgix
                  src={`${card.image.imgix_url}?auto=format`}
                  htmlAttributes={{
                    alt: `Rocket Health Science | Vinex Card - ${card.title}`,
                    src: `${card.image.imgix_url}?auto=format`,
                  }}
                />
              </div>
              <h5>{card.card_title}</h5>
              <div className="vinex__card_body" dangerouslySetInnerHTML={{ __html: card.card_description}} />
            </div>
          ))}
          </div>
        </section>
      )}
      {page_contents.metadata.aims_and_challenges && page_contents.metadata.aims_and_challenges.items && page_contents.metadata.aims_and_challenges.items.length > 0 && (
        <section className="vinex__aims-challenges-container">
          <h4>{page_contents.metadata.aims_and_challenges.title}</h4>
          <div className="challenge-items-container">
            {page_contents.metadata.aims_and_challenges.items && page_contents.metadata.aims_and_challenges.items.map((item, index) => (
              <Aim
                columnCount={page_contents.metadata.aims_and_challenges.items.length}
                key={`aim-${index}`}
                title={item.item_title}
                content={item.item_description}
              />
            ))}
          </div>
        </section>
      )}
      {page_contents.metadata.advisor_list && page_contents.metadata.advisor_list.length > 0 && (
        <section className="vinex__advisory-list-container">
          {page_contents.metadata.advisor_list.map((advisor, index) => (
            <div key={`publication-container-${index}`} className="vinex__publication-container">
              {advisor.title && (<h4 className="advisor-header">{advisor.title}</h4>)}
              <div className="advisory-container">
                {advisor.advisors.map((advisorItem, key) => (
                  <AdvisoryBoard key={`advisor-item-${index}-${key}`} advisoryMember={advisorItem} />
                ))}
              </div>
            </div>
          ))}
        </section>
      )}
      {page_contents.metadata.rocket_science_team_list && page_contents.metadata.rocket_science_team_list.members.length > 0 && (
        <section className="vinex__team-members-container">
          <h4 className="team-header">{page_contents.metadata.rocket_science_team_list.title}</h4>
          <div className="team-members-container">
            {page_contents.metadata.rocket_science_team_list.members.map((member, index) => (
              <TeamMember member={member} key={`member-${index}`} />
            ))}
          </div>
        </section>
      )}
      {page_contents.metadata.advisory_board && page_contents.metadata.advisory_board.length > 0 && (
        <section className="vinex__advisory-container">
          <h4 className="advisor-header">ADVISORY BOARD</h4>
          <div className="advisory-container">
            {page_contents.metadata.advisory_board.map((advisor, index) => (
              <AdvisoryBoard advisoryMember={advisor} key={`advisor-${index}`} />
            ))}
          </div>
        </section>
      )}
      {page_contents.metadata.supporters && page_contents.metadata.supporters.length > 0 && (
        <section className="vinex__supporter-container">
          <h4 className="advisor-header">SUPPORTERS</h4>
          <div className="advisory-container">
            {page_contents.metadata.supporters.map((supporter, index) => (
              <Partner
                content={supporter.metadata.supporter_description}
                image={supporter.metadata.supporter_image.imgix_url}
                title={supporter.title}
                key={`supporter-${index}`}
              />
            ))}
          </div>
        </section>
      )}
      {page_contents.metadata.partners && page_contents.metadata.partners.length > 0 && (
        <section className="vinex__supporter-container">
          <h4 className="advisor-header">PARTNERS</h4>
          <div className="advisory-container">
            {page_contents.metadata.partners.map((partner, index) => (
              <Partner
                title={partner.title}
                content={partner.metadata.partner_description}
                image={partner.metadata.partner_image.imgix_url}
                key={`partner-${index}`}
              />
            ))}
          </div>
        </section>
      )}
      {page_contents.content && mountedContent && (
        <section className="vinex__content-container">
          {mountedContent.length > 0 && mountedContent.map((articleContent, index) => {
            switch (articleContent.type) {
              case 'html':
                return (
                  <div
                    key={`${articleContent.type}-${index}`}
                    id={`${articleContent.index}`}
                    className="vinex__main-content"
                    dangerouslySetInnerHTML={{ __html: articleContent.content }}
                  />
                );
              case 'jsx':
                return articleContent.content;
              default:
                return '';
            }
          })}
        </section>
      )}
      {publications && publications.length > 0 && (
        <section className="vinex__publication-list-container">
          {publications.map((publicationItem, index) => (
            <div key={`publication-container-${index}`} className="vinex__publication-container">
              {publicationItem && publicationItem.header && (
                <h4 className="advisor-header">{publicationItem.header}{publicationItem.subtitle_text && (
                  <>
                  <br/>
                  <span className="pub-subheader">{publicationItem.subtitle_text}</span>
                  </>
                )}</h4>
              )}
              {publicationItem && publicationItem.sub_header && <h3 className="publication-subheader">{publicationItem.sub_header}</h3>}
              <div className="vinex__publications">
                {publicationItem.publications && publicationItem.publications.map((publication, key) => (
                  <PublicationItem key={`publication-item-${index}-${key}`} publication={publication} has_section_image={publicationItem.section_has_images} />
                ))}
              </div>
            </div>
          ))}
        </section>
      )}
      {page_contents.metadata.quote && page_contents.metadata.quote.quote_text && (
        <section className="vinex__quote-container">
          <BottomQuoteCTA
            image={page_contents.metadata.quote.image !== null && page_contents.metadata.quote.image !== undefined ? page_contents.metadata.quote.image.imgix_url : null}
            quote={page_contents.metadata.quote.quote_text}
            author={page_contents.metadata.quote.author}
          />
        </section>
      )}
      {page_contents.metadata.navigation_items && page_contents.metadata.navigation_items.items && (
        <section className="vinex__stories-container">
          {page_contents.metadata.navigation_items.navigation_title !== "" && (
            <h4 className="advisor-header">{page_contents.metadata.navigation_items.navigation_title}</h4>
          )}
          {page_contents.metadata.navigation_items.items && page_contents.metadata.navigation_items.items.map((navItem, index) => (
            <NavigationItem
              key={`navigation-${index}`}
              url={navItem.metadata.page_slug}
              image={navItem.metadata.navigation_image.imgix_url}
              title={navItem.metadata.navigation_title}
              short_text={navItem.metadata.navigation_short_text}
            />
          ))}
        </section>
      )}
      {page_contents.metadata.references && mountedReferences && (
        <section id="references" className="vinex__references-container">
          <h4 className="advisor-header">REFERENCES</h4>
          <div className="vinex__reference-text-container">
            {mountedReferences.length > 0 && mountedReferences.map((mountedReference, index) => {
              switch (mountedReference.type) {
                case 'html':
                  return (
                    <div
                      key={`${mountedReference.type}-${index}`}
                      id={`${mountedReference.index}`}
                      className="vinex__main-content"
                      dangerouslySetInnerHTML={{ __html: mountedReference.content }}
                    />
                  );
                case 'jsx':
                  return mountedReference.content;
                default:
                  return '';
              }
            })}
          </div>
        </section>
      )}
    </Layout>
  )
};

VinexTemplate.propTypes = {
  pageContext: PropTypes.any,
};

VinexTemplate.defaultProps = {
  pageContext: {},
};

export default VinexTemplate;
